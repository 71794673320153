import React from "react"
import TemplatePageEbook  from "@components/pageEbook/template"
import { Helmet } from "react-helmet"
import data from "@components/pageEbook/ebooks/ebook3/dataPageEbook3"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        {" "}
        ¿Cómo gestionar cobranzas en tiempos de pandemia? - Guía completa
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/ebook/cobranzas-pandemia-ebook/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Conoce la guía gratuita sobre el manejo de las cobranzas en tiempos de pandemia que te ayudará a optimizar tus campañas para que tengan éxito."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="¿Cómo gestionar cobranzas en tiempos de pandemia? - Guía completa"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/ebook/cobranzas-pandemia-ebook/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Conoce la guía gratuita sobre el manejo de las cobranzas en tiempos de pandemia que te ayudará a optimizar tus campañas para que tengan éxito."
      />
    </Helmet>
    <TemplatePageEbook ebook="Cobranzas" location={location} typeEbook="ebook3" clickHereHref="https://beexcc.com/blog/cobranzas-para-contact-center/" data={data} />
  </div>
)

export default IndexPage
