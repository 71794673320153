import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook3/image/imgPortadaEbook3.png"
import img1 from "@components/pageEbook/ebooks/ebook3/image/imgCard1.png"
import img2 from "@components/pageEbook/ebooks/ebook3/image/imgCard2.png"
import img3 from "@components/pageEbook/ebooks/ebook3/image/imgCard3.png"
import slider1 from "@components/pageEbook/ebooks/ebook3/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook3/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook3/image/imgSilder3.png"
import slider4 from "@components/pageEbook/ebooks/ebook3/image/imgSilder4.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
      E-book: ¿Cómo gestionar cobranzas en tiempos de pandemia?
    </h1>
  </>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook">
      La COVID-19 afectó directamente en la liquidez de las personas y de las
      empresas. Y, como consecuencia, el resultado de las campañas de cobranza
      por el aumenta de la falta de pago.
      <br /> <br />
      De este modo, en la edición de este e-book se plantea una nueva propuesta
      de las telecobranzas, no solo para el contexto en el que vivimos sino para
      estar preparados para el futuro.
    </p>
  </div>
)

const textOne = (
  <p>
    Es necesario replantear las estrategias de cobranza con el inicio de la
    pandemia. Esto, debido a que el comportamiento del usuario o de las empresas
    ya no será el mismo.
    <br /> <br />
    Un primer paso está en replantear la segmentación de los deudores para
    brindarle mensajes personalizados que aseguren un compromiso de pago.
  </p>
)
const textTwo = (
  <p>
    Se necesita tener una rápida capacidad de respuesta ante cualquier
    situación. Esto, porque la coyuntura impredecible somete a las decisiones de
    un usuario a la hora de cancelar sus deudas.
    <br /> <br />
    Es fundamental estar actualizando los datos de los deudores y entender por
    qué dejaron de pagar y analizar todos sus comportamientos para impulsar
    nuevas campañas de tu centro de contacto.
  </p>
)
const textThree = (
  <p>
    La principal lección de la pandemia fue la migración de los call center a la
    nube. Así, tanto la información de la empresa como su operatividad estarán a
    salvo.
  </p>
)

const data = {
  start: {
    support: "Ebook | Cobranzas",
    title: title,
    description: textDescription,
    classtext: "text-description-ebook-ecommerce",
    link: linkForm,
    href: "/ebook-3.pdf",
    brochure: "ebook-cobranzas",
    img: imgPortadaEbook,
  },

  description: {
    cards: [
      {
        title: "¿Cómo hacer cobranzas en tiempos de pandemia?",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Nuevo enfoque a las cobranzas en tiempos de pandemia",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Lecciones de la COVID-19 en las estrategias de cobranza",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Perfeccionar estrategias en escenarios de crisis como una pandemia.",
      },
      {
        key: 2,
        text: "Valorar la importancia de la evaluación de las cobranzas en tiempos de crisis.",
      },
      {
        key: 3,
        text: "Conocer nuevos enfoques de las cobranzas postpandemia.",
      },
      {
        key: 4,
        text: "Reconocer el aprendizaje que dejó la COVID-19 en las estrategias de cobranza.",
      },
      {
        key: 5,
        text: "Crear nuevas propuestas para las telecobranzas",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
      {
        key: 4,
        img: slider4,
      },
    ],
  },
}

export default data
